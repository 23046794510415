export const base = {
  "src.components.routes.pages.Root.title": "Customer Space",
  "src.components.routes.pages.Login.title": "Zadejte své telefonní číslo",
  "src.components.routes.pages.NotFound.title": "NotFound",
  "error.name.required": "test",
  "src.components.organisms.NotFoundBlock.title": "Stránka nenalezena",
  "error.login.required": "Telefon je povinný",
  "error.comment.required": "Komentář je povinný",
  "error.problem.required":"Vyplňte prosím toto pole.",
  "src.components.atoms.Copyright.text":
    "Copyright ©{currentYear} NutritionPro s.r.o",
  "src.components.organisms.forms.LoginForm.placeholder":
    "777 777 777",
  "src.components.organisms.forms.LoginForm.label": "Tvůj telefon",
  "src.components.organisms.forms.LoginForm.btn": "Přihlásit se",
  "error.checkbox.required": "required",
  "src.components.molecules.InfoAlert.info":
    "Váš jídelníček můžete začít měnit nejdříve 7 dní ode dneška.",
  "src.components.molecules.InfoAlert.warning":
    "Poznámka: Pokrmy mohou obsahovat stopy alergenů.",
  "src.components.organisms.forms.PhoneForm.placeholder": "Telefon",
  "src.components.organisms.forms.PhoneForm.label": "Telefon",
  "src.components.organisms.forms.PhoneForm.btn": "Přihlásit se",
  "src.components.organisms.PhoneModal.title": "Zadejte své telefonní číslo",
  "src.components.organisms.forms.CommentForm.placeholder": "Toto jídlo mi opravdu chutnalo.",
  "src.components.organisms.forms.CommentForm.label": "",
  "src.components.organisms.forms.CommentForm.submitBtn": "Uložit",
  "src.components.organisms.forms.CommentForm.btn": "Zrušit",
  "src.components.organisms.CommentModal.title": "Přidat komentář",
  "src.components.organisms.RateModal.title":
    "Pomozte nám udělat NutritionPro chutnější",

  "src.components.organisms.forms.RateForm.placeholder": "Text",
  "src.components.organisms.forms.RateForm.label": "Text",
  "error.rate.required": "test",
  "src.components.organisms.forms.RateForm.submitBtn": "Uložit",
  "src.components.organisms.forms.RateForm.btn": "Zrušit",
  "src.components.organisms.forms.RateForm.rate": "Hodnocení dodaní",
  "src.components.organisms.forms.RateForm.mark": "Hodnocení pokrmu",
  "src.components.organisms.ExpectionsModal.title": "Seznam vyjímek",
  "src.components.molecules.ExpectionsAlert.exchange":
    "Zvolte ingredience, které chcete vyřadit a my se vám pokusíme sestavit nové menu bez nich.",
  "src.components.molecules.ExpectionsAlert.nuts":
    "Jestliže nebudou žádné náhrady, pokrm zůstane nezměněný.",
  "src.components.organisms.forms.ExpectionsForm.submit": "Uložit",
  "src.components.organisms.forms.ExpectionsForm.btn": "Zrušit",
  "src.components.molecules.ChangeFoodAccordion.title": "Změna jídla",
  "src.components.molecules.HeaderInfo.title": "Dobrý NutritionPro den {name}!",
  "src.components.molecules.HeaderInfo.text":
    "Dnes jsme připravili jídelníček speciálně pro Vás.",
  "src.components.organisms.RateModal.btn":
    "Pomozte nám udělat NutritionPro chutnější",
  "src.components.organisms.Header.btn": "Odhlásit",
  "src.components.organisms.Header.installBtn": "Přidat na plochu",
  "src.components.organisms.ExpectionsModal.btn": "Vyloučit ingredience",
  "src.components.molecules.EmptyDays.title":
    "Vaše nabídka pro nadcházející dodávku ještě není připravena",
  "src.components.molecules.EmptyDays.subTitle":
    "Nebojte se, objeví se blíže k datům dodání",
  "src.components.molecules.DeliveryInfoBlock.day":
    "Poslední den objednávek: {value}",
  "src.components.molecules.DeliveryInfoBlock.address":
    "Dodací adresa: {value}",
  "src.components.molecules.DeliveryInfoBlock.time":
    "Preferovaný čas doručení: {value}",
  "src.components.molecules.TotalDayCardInfo.text": "Celkem za den",
  "src.components.molecules.MenuCard.breakfast": "Snídaně",
  "src.components.molecules.MenuCard.snack1": "Svačina 1",
  "src.components.molecules.MenuCard.lunch": "Oběd",
  "src.components.molecules.MenuCard.snack2": "Svačina 2",
  "src.components.molecules.MenuCard.dinner": "Večeře",
  "src.components.atoms.DishIsHotBadge.text": "Ohřívat",
  "src.components.atoms.DishChoicedBadge.text": "Vybráno Vámi",

  "src.components.molecules.Nutritions.kcal": "KCal",
  "src.components.molecules.Nutritions.prot": "Bílkoviny",
  "src.components.molecules.Nutritions.fat": "Tuky",
  "src.components.molecules.Nutritions.carb": "Sacharidy",
  "src.components.molecules.ChoiceOptionFooterInformer.text":
    "Tento pokrm je podáván {date} na {meal}",

  "src.components.molecules.ChoiceOptionFooterInformer.monday": "Pondělí",
  "src.components.molecules.ChoiceOptionFooterInformer.tuesday": "úterý",
  "src.components.molecules.ChoiceOptionFooterInformer.wednesday": "středa",
  "src.components.molecules.ChoiceOptionFooterInformer.thursday": "čtvrtek",
  "src.components.molecules.ChoiceOptionFooterInformer.friday": "pátek",
  "src.components.molecules.ChoiceOptionFooterInformer.saturday": "sobota",
  "src.components.molecules.ChoiceOptionFooterInformer.sunday": "neděle",
  "src.components.molecules.ChangeFoodAccordion.save": "Uložit",
  "src.components.organisms.CommentModal.btn": "Přidat komentář",
  "src.components.molecules.InfoAlert.google":
    "Ohodnoťte NutritionPro na Google",
    "src.components.molecules.DeliveryInfoBlock.title":"Aktuální stav programu",
    "src.components.organisms.MenuDayCarousel.title":"Váš jídelníček",
    "src.components.routes.pages.Login.description":"pro přihlášení do klientského portálu NutritionPro",
    "src.components.routes.pages.Root.prelongBtn":"Prodloužít program online",
    "src.components.molecules.DeliveryInfoBlock.daysLeft":"( zbyvá {value} dní)",
    "error.paymentMethod.required": "Payment method je vyžadováno.",
    "src.components.organisms.OnlineOrderThiredStep.platba.gopay":
    "Zabezpečenou platbu poskytuje",
    "src.components.organisms.OnlineOrderThiredStep.platba": "Zvolte platbu",
    "src.components.organisms.OnlinePrelongBlock.userInfo": "Osobní údaje",
    "src.components.organisms.OnlinePrelongBlock.programTitle": "Můj program",
    "src.components.organisms.OnlinePrelongBlock.length":"{value} dnů",
    "src.components.templates.OnlineOrderDesctopTemplate.sidebarTitile":
    "Shrnutí objednávky",
    "src.components.templates.OnlinePrelongTemplate.title":
    " Prodloužení programu",
    "src.components.templates.OnlineOrderMobileTemplate.close": "Zrušit",
    "src.components.molecules.OnlineOrderThiredStepSidebar.priceDay":
    "Cena za menu (bez dopravy):",
    "src.components.atoms.ConfirmPrice.price": "{price} Kč",
    "src.components.molecules.OnlineOrderThiredStepSidebar.priceDelivery":
    "Cena dopravy za den:",
    "src.components.atoms.ConfirmPrice.text": "Cena celkem (vč. DPH)",
    "src.components.molecules.OrderRightCard.pocrovat": "Pokračovat",
    "src.components.molecules.OnlineOrderThiredStepSidebar.priceTotal":
    "Cena za celé období:",
    "src.components.routes.pages.Prelong.title":"Preelong",
    "src.components.organisms.OnlinePrelongBlock.days":"{value} dní",
    "src.components.organisms.OnlinePrelongBlock.porcel":"porce: {value}",
    "src.components.organisms.OnlinePrelongBlock.durationTitle":"Prodloužit program o",
    "src.components.routes.pages.ThankYou.title":"Thank you",
    "src.components.organisms.ThankYouModal.succsesTitle":"Vaše žádost byla úspěšně přijata",
    "src.components.organisms.ThankYouModal.btn":"Zpět na hlavní stránku",
    "src.components.organisms.CalendarModal.title":"Vyberte rozsah pauzy",
    "src.components.organisms.CalendarModal.btn":"Uložit",
    "src.components.organisms.CalendarModal.pauseTitle":"Požádat o {bold}",
    "src.components.organisms.CalendarModal.pauseTitleBold":"pauzu",
    "src.component.organisms.SendMailBlock.title":"Další možnosti",
    "src.component.organisms.SendMailBlock.info":"Připomínáme, že doručovací adresu a čas lze změnit pouze do 23:59 den před doručením.",
    "src.components.organisms.DeliveryAddressModal.titleBold":"změnu doručovací adresy",
    "src.components.organisms.DeliveryAddressModal.title":"Požádat o jednorázovou {bold}",
    "src.components.organisms.TimeSlotModal.title":"Požádat o jednorázovou {bold}",
    "src.components.organisms.TimeSlotModal.titleBold":" změnu doručení",
    "src.component.organisms.ReportProblemBlock.title":"Něco nejde? Dej nám vědět.",
    "src.components.organisms.ReportDelivery.title":"Nahlásit problém s doručením >>",
    "src.components.organisms.ReportDelivery.modal.title":"Nahlásit problém",
    "src.components.organisms.ReportFood.title":"Nahlásit problém s jídlem >>>",
    "src.components.organisms.ReportFood.modal.title":"Nahlásit problém",
    "src.components.organisms.ReportPortal.title":"Nahlásit problém s portalem >>>",
    "src.components.organisms.ReportPortal.modal.title":"Nahlásit problém",
    "src.components.organisms.forms.ReportForm.placeholder":"Nahlásit problém",
    "src.components.organisms.forms.ReportForm.submitBtn":"Odeslat",
    "src.components.organisms.forms.ReportForm.btn":"Zrušit",
    "src.components.organisms.CalendarModal.reset":"Resetovat",
    "src.components.organisms.TimeSlotModal.morning":"Ranní časová okna rozvozu",
    "src.components.organisms.TimeSlotModal.evening":"Večerní časová okna rozvozu",
    "src.components.molecules.DeliveryAddressCheck.tab1": "Doručení na adresu",
  "src.components.molecules.DeliveryAddressCheck.tab2":
    "Doručení na odběrné místo",
  "src.components.molecules.OnlineOrderAddressCheck.city": "",
  "src.components.moelcules.OnlineOrderAddressCheck.city": "",
  "src.components.molecules.OnlineOrderAddressCheck.pickUpPoint": "",
  "src.components.moelcules.OnlineOrderAddressCheck.pick": "",
  "src.components.molecules.OnlineOrderAddressCheck.address": "",
  "src.components.molecules.DeliveryAddressCheck.city": "Město",
  "src.components.molecules.DeliveryAddressCheck.address": "Adresa",
  "src.components.molecules.DeliveryAddressCheck.pickUpPoint": "Odberné místo",
  "src.components.moelcules.DeliveryAddressCheck.city": "Vyplňte město",
  "src.components.moelcules.DeliveryAddressCheck.pick": "Vyber odberné místo",
  "src.component.atoms.AddressInput.button": "Potvrdit",
  "src.component.atoms.AddressInput.placeHolder": "Vyplňte vaši ulici a č.p.",
  "src.component.atoms.AddressInput.error":
    "Je nám líto! Zatím v této lokalitě nejsme.",
  "src.component.atoms.AddressInput.success": "Doprava zdarma",
  "error.pickUpPoint.required": "Vaší odberné místo",
  "error.addressCity.required": "Zadejte Vaší adresu",
  "error.date.required": "Date je povinný",
  "src.components.molecules.PromoCodeApprove.promoCode": "Promokód ",
  "src.components.molecules.PromoCodeApprove.remove": "Odebrat",
  "src.components.organisms.forms.PromoCodeForm.btn": "Uplatnit",
  "src.components.organisms.forms.PromoCodeForm.errorMessage":
    "Tento promo kód není platný",
  };
