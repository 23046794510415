export const base = {
  "src.components.routes.pages.Root.title": "Customer Space",
  "src.components.routes.pages.Login.title": "Please enter your phone number",
  "src.components.routes.pages.NotFound.title": "NotFound",
  "error.name.required": "test",
  "src.components.organisms.NotFoundBlock.title": "Page not found",
  "error.login.required": "Phone is required",
  "error.comment.required": "Comment is required",
  "error.problem.required":"Vyplňte prosím toto pole.",
  "src.components.organisms.forms.LoginForm.placeholder": "777 777 777",
  "src.components.organisms.forms.LoginForm.label": "Your phone",
  "src.components.organisms.forms.LoginForm.btn": "Sign in  ",
  "error.checkbox.required": "required",
  "src.components.atoms.Copyright.text":
    "Copyright ©{currentYear} NutritionPro s.r.o",
  "src.components.molecules.InfoAlert.info":
    "You can start changing your menu no sooner than 7 days from today",
  "src.components.molecules.InfoAlert.warning":
    "Dishes may contain traces of allergens",
  "src.components.organisms.forms.PhoneForm.placeholder": "Telefon",
  "src.components.organisms.forms.PhoneForm.label": "Telefon",
  "src.components.organisms.forms.PhoneForm.btn": "Přihlásit se",
  "src.components.organisms.PhoneModal.title": "Please enter your phone number",
  "src.components.organisms.forms.CommentForm.placeholder":
    "I really liked this dish.",
  "src.components.organisms.forms.CommentForm.label": "",
  "src.components.organisms.forms.CommentForm.submitBtn": "Submit",
  "src.components.organisms.forms.CommentForm.btn": "Cancel",
  "src.components.organisms.CommentModal.title": "Add comment",
  "src.components.organisms.RateModal.title":
    "Help us make NutritionPro tastier",

  "src.components.organisms.forms.RateForm.placeholder": "Text",
  "src.components.organisms.forms.RateForm.label": "Text",
  "error.rate.required": "test",
  "src.components.organisms.forms.RateForm.submitBtn": "Submit",
  "src.components.organisms.forms.RateForm.btn": "Cancel",
  "src.components.organisms.forms.RateForm.rate": "Delivery score",
  "src.components.organisms.forms.RateForm.mark": "Food score",
  "src.components.organisms.ExpectionsModal.title": "List of exceptions",
  "src.components.molecules.ExpectionsAlert.exchange":
    "Select unwanted ingredients and we will create a new menu without them",
  "src.components.molecules.ExpectionsAlert.nuts":
    "If there are no substitutes, the order will remain unchanged",
  "src.components.organisms.forms.ExpectionsForm.submit": "Submit",
  "src.components.organisms.forms.ExpectionsForm.btn": "Cancel",
  "src.components.molecules.ChangeFoodAccordion.title": "Change meal",
  "src.components.molecules.HeaderInfo.title":
    "Good NutritionPro afternoon {name}!",
  "src.components.molecules.HeaderInfo.text":
    "Today we have prepared a menu especially for you",
  "src.components.organisms.RateModal.btn": "Help us make NutritionPro tastier",
  "src.components.organisms.Header.btn": "Sign Out",
  "src.components.organisms.Header.installBtn": "Install",
  "src.components.organisms.ExpectionsModal.btn": "Eliminate ingredients",
  "src.components.molecules.EmptyDays.title":
    "Your offer for the upcoming delivery is not yet ready",
  "src.components.molecules.EmptyDays.subTitle":
    "Don't worry, they will appear closer to the delivery dates",
  "src.components.molecules.DeliveryInfoBlock.day":
    "Last day of order: {value}",
  "src.components.molecules.DeliveryInfoBlock.address":
    "Shipping address: {value}",
  "src.components.molecules.DeliveryInfoBlock.time":
    "Preferred delivery time: {value}",
  "src.components.molecules.TotalDayCardInfo.text": "Total for day",
  "src.components.molecules.MenuCard.breakfast": "Breakfast",
  "src.components.molecules.MenuCard.snack1": "Snack 1",
  "src.components.molecules.MenuCard.lunch": "Lunch",
  "src.components.molecules.MenuCard.snack2": "Snack 2",
  "src.components.molecules.MenuCard.dinner": "Dinner",
  "src.components.atoms.DishIsHotBadge.text": "Heat up",
  "src.components.atoms.DishChoicedBadge.text": "Selected by you",

  "src.components.molecules.Nutritions.kcal": "KCal",
  "src.components.molecules.Nutritions.prot": "Proteins",
  "src.components.molecules.Nutritions.fat": "Fats",
  "src.components.molecules.Nutritions.carb": "Carbohydrates",
  "src.components.molecules.ChoiceOptionFooterInformer.text":
    "This dish is presented on {date} for {meal}",
  "src.components.molecules.ChoiceOptionFooterInformer.monday": "monday",
  "src.components.molecules.ChoiceOptionFooterInformer.tuesday": "tuesday",
  "src.components.molecules.ChoiceOptionFooterInformer.wednesday": "wednesday",
  "src.components.molecules.ChoiceOptionFooterInformer.thursday": "thursday",
  "src.components.molecules.ChoiceOptionFooterInformer.friday": "friday",
  "src.components.molecules.ChoiceOptionFooterInformer.saturday": "saturday",
  "src.components.molecules.ChoiceOptionFooterInformer.sunday": "sunday",
  "src.components.molecules.ChangeFoodAccordion.save": "Save changes",
  "src.components.organisms.CommentModal.btn": "Add comment",
  "src.components.molecules.InfoAlert.google": "Rate NutritionPro on Google",
  "src.components.molecules.DeliveryInfoBlock.title":
    "Current status of the program",
  "src.components.organisms.MenuDayCarousel.title": "Your menu",
  "src.components.routes.pages.Login.description":
    "to login to the NutritionPro client portal",
  "src.components.routes.pages.Root.prelongBtn": "Prodloužít program online",
  "src.components.molecules.DeliveryInfoBlock.daysLeft": "( zbyvá {value} dní)",
  "src.components.organisms.OnlineOrderThiredStep.platba.gopay":
    "Secure payment provided by",
  "src.components.organisms.OnlineOrderThiredStep.platba": "Choose Payment",
  "src.components.organisms.OnlinePrelongBlock.userInfo": "Osobní údaje",
  "src.components.organisms.OnlinePrelongBlock.programTitle": "Můj program",
  "src.components.organisms.OnlinePrelongBlock.length": "{value} days",
  "src.components.templates.OnlineOrderDesctopTemplate.sidebarTitile":
    "Order summary",
  "src.components.templates.OnlinePrelongTemplate.title":
    " Prodloužení programu",
  "src.components.templates.OnlineOrderMobileTemplate.close": "Close",
  "src.components.molecules.OnlineOrderThiredStepSidebar.priceDay":
    "Price per menu (excluding delivery):",
  "src.components.atoms.ConfirmPrice.price": "{price} CZK",
  "src.components.molecules.OnlineOrderThiredStepSidebar.priceDelivery":
    "Delivery price per day:",
  "src.components.atoms.ConfirmPrice.text": "Total price (incl. VAT)",
  "src.components.molecules.OrderRightCard.pocrovat": "Continue",
  "src.components.molecules.OnlineOrderThiredStepSidebar.priceTotal":
    "Price for the entire period:",
  "src.components.routes.pages.Prelong.title": "Preelong",
  "src.components.organisms.OnlinePrelongBlock.days": "{value} dní",
  "src.components.organisms.OnlinePrelongBlock.porcel":"porce: {value}",
  "src.components.organisms.OnlinePrelongBlock.durationTitle":
    "Prelong the program to",
  "src.components.routes.pages.ThankYou.title": "Thank you",
  "src.components.organisms.ThankYouModal.succsesTitle":
    "Vaše žádost byla úspěšně přijata",
  "src.components.organisms.ThankYouModal.btn": "Zpět na hlavní stránku",
  "src.components.organisms.CalendarModal.title":"Vyberte rozsah pauzy",
  "src.components.organisms.CalendarModal.btn":"Save",
  "src.components.organisms.CalendarModal.pauseTitle":"Požádat o {bold}",
  "src.components.organisms.CalendarModal.pauseTitleBold":"pauzu",
  "src.component.organisms.SendMailBlock.title":"Další možnosti",
  "src.component.organisms.SendMailBlock.info":"Připomínáme, že doručovací adresu a čas lze změnit pouze do 23:59 den před doručením.",
  "src.components.organisms.DeliveryAddressModal.titleBold":"změnu doručovací adresy",
  "src.components.organisms.DeliveryAddressModal.title":"Požádat o jednorázovou {bold}",
  "src.components.organisms.TimeSlotModal.title":"Požádat o jednorázovou {bold}",
  "src.components.organisms.TimeSlotModal.titleBold":" změnu doručení",
  "src.component.organisms.ReportProblemBlock.title":"Něco nejde? Dej nám vědět.",
  "src.components.organisms.ReportDelivery.title":"Nahlásit problém s doručením >>",
  "src.components.organisms.ReportDelivery.modal.title":"Nahlásit problém",
  "src.components.organisms.ReportFood.title":"Nahlásit problém s jídlem >>>",
  "src.components.organisms.ReportFood.modal.title":"Nahlásit problém",
  "src.components.organisms.ReportPortal.title":"Nahlásit problém s portalem >>>",
  "src.components.organisms.ReportPortal.modal.title":"Nahlásit problém",
  "src.components.organisms.forms.ReportForm.placeholder":"Nahlásit problém",
  "src.components.organisms.forms.ReportForm.submitBtn":"Send",
  "src.components.organisms.forms.ReportForm.btn":"Zrušit",
  "src.components.organisms.CalendarModal.reset":"Reset",
  "src.components.organisms.TimeSlotModal.morning":"Ranní časová okna rozvozu",
  "src.components.organisms.TimeSlotModal.evening":"Večerní časová okna rozvozu",
  "src.components.molecules.DeliveryAddressCheck.tab1": "Doručení na adresu",
  "src.components.molecules.DeliveryAddressCheck.tab2":
    "Doručení na odběrné místo",
  "src.components.molecules.OnlineOrderAddressCheck.city": "",
  "src.components.moelcules.OnlineOrderAddressCheck.city": "",
  "src.components.molecules.OnlineOrderAddressCheck.pickUpPoint": "",
  "src.components.moelcules.OnlineOrderAddressCheck.pick": "",
  "src.components.molecules.OnlineOrderAddressCheck.address": "",
  "src.components.molecules.DeliveryAddressCheck.city": "Město",
  "src.components.molecules.DeliveryAddressCheck.address": "Adresa",
  "src.components.molecules.DeliveryAddressCheck.pickUpPoint": "Odberné místo",
  "src.components.moelcules.DeliveryAddressCheck.city": "Vyplňte město",
  "src.components.moelcules.DeliveryAddressCheck.pick": "Vyber odberné místo",
  "src.component.atoms.AddressInput.button": "Potvrdit",
  "src.component.atoms.AddressInput.placeHolder": "Vyplňte vaši ulici a č.p.",
  "src.component.atoms.AddressInput.error":
    "Je nám líto! Zatím v této lokalitě nejsme.",
  "src.component.atoms.AddressInput.success": "Doprava zdarma",
  "error.pickUpPoint.required": "Vaší odberné místo",
  "error.addressCity.required": "Zadejte Vaší adresu",
  "error.date.required": "Date je povinný",
  "src.components.molecules.PromoCodeApprove.promoCode": "Promo Code",
  "src.components.molecules.PromoCodeApprove.remove": "Remove",
  "src.components.organisms.forms.PromoCodeForm.btn": "Apply",

  "src.components.organisms.forms.PromoCodeForm.errorMessage":
    "This promo code is not valid",
};
